.page-sidebar {
  transition: transform .3s cubic-bezier(0, .52, 0, 1);

  .sidebar-menu {
    overflow: auto;

    ul.menu-items {
      padding: 0 20px;
      li {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        padding: .5rem 1rem;
        transition: .3s all ease-in;
  
        svg {
          width: 20px;
          height: auto;
        }
      }
  
      li.active,
      li:hover {
        position: relative;
        background-color: #C7DCBD;
        border-radius: 10px;
      }

      ul.sub-menu {
        li.active,
        li:hover {
          background-color: #F1FFDE;
          color: #5E9942;
        }
      }
    }
  }
  
  &.show {
    transform: translate3d(0px, 0, 0) !important;
  }

  @media (max-width: 1023.5px) {
    // Hide Menu
    transform: translate3d(-288px, 0, 0);
  }
}
