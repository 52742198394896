.phone-input {
  font-size: 14px !important;
  font-family: 'Poppins', 'Helvetica Neue', sans-serif !important;

  .special-label {
    color: #5E9942;
    left: 16px !important;
    padding: 0 8px !important;
    font-size: 12px !important;
  }
}

.my-custom-checkbox {
  position: relative;
  padding: 0 0 0 30px;
  text-align: left;

  label {
    cursor: pointer;
    margin-top: 2px;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 15px;
      border-radius: 2px;
    }
    &:before {
      top: 3px;
      left: 0;
      width: 20px;
      height: 20px;
      margin: 0;
      background: #f7f7f7;
      border: 2px solid #5E9942;
      box-shadow: 0 0 1px #5E9942;
    }
    &:after {
      top: 16px;
      left: 3px;
      width: 14px;
      height: 14px;
      margin: -10px 0 0;
      opacity: 0;
      background: #5E9942;
      transform: translate3d(-40px, 0, 0) scale(0.5);
      transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
    }
  }

  input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: -9999px;
    visibility: hidden;

    &:checked + label {
      &:after {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }
  }
}

.editor-container,
.desc-container {
  .ck.ck-editor {
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;

    .ck-editor__top {
      .ck-toolbar {
        border-radius: 4px 4px 0 0 !important;
        -moz-border-radius: 4px 4px 0 0 !important;
        -webkit-border-radius: 4px 4px 0 0 !important;
      }
    }

    .ck-editor__main {
      .ck-editor__editable {
        height: 230px;
        border-radius: 0 0 4px 4px;
        -moz-border-radius: 0 0 4px 4px;
        -webkit-border-radius: 0 0 4px 4px;
      }
      .ck-focused {
        outline: none;
      }
    }
  }

  ol {
    list-style: decimal;
    padding-left: 40px;
  }
  ul {
    list-style: disc;
    padding-left: 40px;
  }
  a {
    text-decoration: underline;
    color: rgb(4, 87, 182);
  }
}

.custom-select__indicator {
  padding-left: 0 !important;
  svg {
    color: #1A1A1A;
  }
}
