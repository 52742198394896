@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --primary-color: 94, 153, 66;
  }

  .dark {
    --primary-color: 136, 35, 35;
  }
}

body {
  font-size: 14px;
  color: #1C1C1C;
  font-family: 'Poppins', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.file-input input::file-selector-button {
  @apply bg-[#2050F5] text-white outline-none border-none text-sm px-5 py-3 rounded -my-3 -ml-2 cursor-pointer hover:bg-opacity-70
}

.rnc__notification-item--info,
.rnc__notification-item--danger,
.rnc__notification-item--success,
.rnc__notification-item--warning {
  border-left: none !important;
}

.rnc__notification-container--top-right {
  top: 90px !important;
}
